import { XIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { storage } from 'lib/local-storage'
import { useEffect, useState } from 'react'

const Banner: FC = () => {
  const ctx = useUserContext()
  const [show, setShow] = useState(false)

  const currentBannerId = 'ozon_23_08'

  useEffect(() => {
    setShow(
      ctx.theme.hostType === 'sellmonitor' &&
        !storage.get(`bannerClosed:${currentBannerId}` as any) &&
        dayjs().isBefore('2024-08-23T17:00:00+03:00')
    )
  }, [])

  if (!show) return null

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        aria-hidden="true"
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#005BFF] to-[#C3B6FD] opacity-30"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#005BFF] to-[#C3B6FD] opacity-30"
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Практический вебинар с Ozon</strong>
          <span className="mx-1.5">&middot;</span>23 августа в 16:00 МСК покажем, как увеличить
          продажи с помощью контента
        </p>
        <a
          href="https://webinar.sellmonitor.com/ozon"
          target="_blank"
          rel="noreferrer"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Зарегистрироваться <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 inline-flex items-center justify-center focus-visible:outline-offset-[-4px]"
          onClick={() => {
            storage.set(`bannerClosed:${currentBannerId}` as any, true)
            setShow(false)
          }}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
        </button>
      </div>
    </div>
  )
}

export default Banner
